import {useEffect, useState, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import { changeThemeAction } from "../../redux/themeDucks";
import { redux_updateOrders,getPropertiesAction,clearPropertiesAction,changeTypeList} from "../../redux/propertiesDucks";
import BreadCrumbs from '../breadcrumbs'

const Main = () =>{
    const dispatch = useDispatch()
    const [location, setLocation] = useState(window.location.pathname.split("/"));
    const [orders,setOrders] = useState({order:'desc',order_by:'price'})
    const theme = useSelector((store) => store.theme);
    const settings = useSelector(store => store.settings)
    const {data} = useSelector(store => store.properties.properties)
  
    const selectorOrder = useRef()
  
    useEffect(() => {
      setLocation(window.location.pathname.split("/"));
    }, [theme.location]);
  
    function toggleFilters() {
      $("#filter").toggleClass("open");
      $(".fixed-bar").toggleClass("d-none")
      $("html").toggleClass("overflow-hidden-mobile");
    }
  
    function checkView(){
      if(theme.location == 'ventas' || location.find(element => element === 'ventas')){
        return 'Venta'
      }
      return 'Alquiler'
    }
  
    function traductorSelect(){
      switch(selectorOrder.current.value){
        case '0': setOrders({order:'desc',order_by:'price'}); break;
        case '1': setOrders({order:'asc',order_by:'price'}); break;
        case '2': setOrders({order:'desc',order_by:'age'}); break;
        case '3': setOrders({order:'asc',order_by:'age'}); break;
        case '4': setOrders({order:'desc',order_by:'roofed_surface'}); break;
        case '5': setOrders({order:'asc',order_by:'roofed_surface'}); break;
      }
    }
  
    useEffect(() =>{
      $(document).ready(function() {
        $('#resizing_select').change(function(){
          $("#width_tmp_option").html($('#resizing_select option:selected').text()); 
          $(this).width($("#width_tmp_select").width());  
        });
      });
    }) 
  
    useEffect(() => {
      dispatch(redux_updateOrders(orders))
    },[orders])
    return (
        <section className="main">
            <div className="container-fluid px-lg-5">
                <button className="btn-filters d-block d-lg-none" onClick={() => toggleFilters()}> <i className="icon-filter me-3"></i>Filtros</button>
                <div className="d-block d-lg-none">
                  <BreadCrumbs props={[{name:'Home',route:settings.base_url,location:''},{name:location[1].replace(/^\w/, (c) => c.toUpperCase()),route:settings.base_url + location[1] ,location:location[1],active:true}]}/>
                  <h5>{data ? data.meta.total_count +  ' resultado total de la búsqueda' : 'Cargando resultados totales...'}</h5>
                </div>
                <div className="d-flex justify-content-between align-items-end">
                    <div>
                      <div className="d-none d-lg-block">
                        <BreadCrumbs props={[{name:'Home',route:settings.base_url,location:''},{name:location[1].replace(/^\w/, (c) => c.toUpperCase()),route:settings.base_url + location[1] ,location:location[1],active:true}]}/>
                        <h5>{data ? data.meta.total_count +  ' resultado total de la búsqueda' : 'Cargando resultados totales...'}</h5>
                      </div>
                        <h2> <div className="d-inline-block text-capitalize"></div>Propiedades en <span className="regionName text-capitalize"> {checkView(location[1])} </span></h2>
                    </div>
                    <div>
                        <span className="d-lg-block d-none text-end">VER: <button onClick={() => dispatch(changeTypeList(false))}  className="ms-2 cursor-pointer"><i className="icon-grid"></i></button> <button onClick={() => dispatch(changeTypeList(true))} className="ms-2 cursor-pointer"><i className="icon-row"></i></button></span>
                          <span className="d-flex align-items-end order-mobile text-end ">
                            <div className="d-lg-inline-block d-none"> Ordenar por: </div>
                            <select ref={selectorOrder} onChange={() => traductorSelect()} name="orderby" id="resizing_select">
                              <option value="0">Mayor precio</option>
                              <option value="1">Menor precio</option>
                              <option value="2">Mayor antigüedad</option>
                              <option value="3">Menor antigüedad</option>
                              <option value="4">Mayor superficie</option>
                              <option value="5">Menor superficie</option>
                            </select>
                            <select id="width_tmp_select">
                              <option id="width_tmp_option"></option>
                            </select>
                        </span>
                    </div>
                </div>
            </div>
        </section>
    )
} 

export default Main