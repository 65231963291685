import {useDispatch,useSelector} from 'react-redux'
import { changeLocationAction } from '../redux/themeDucks';
import { Link } from "react-router-dom";

const Breadcrumbs = props =>{
    const dispatch = useDispatch();
    const settings = useSelector((store) => store.settings);

    return (
        <div>
            <nav aria-label="breadcrumb" className="mb-lg-5">
                <ol className="breadcrumb">
                    {props.props.map((item,index) =>(
                        <li className={'breadcrumb-item'}> { (item.active ?  item.name : <Link onClick={() => dispatch(changeLocationAction(item.location))} to={item.route}>{item.name}</Link>)} </li>
                    ))}
                </ol>
            </nav>
        </div>
    )
}

export default Breadcrumbs