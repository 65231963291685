import { useSelector } from 'react-redux'
import BreadCrumbs from '../breadcrumbs'

const   Main = () =>{
    const settings = useSelector(store => store.settings)
    return (
        <section className="main">
            <div className="container-fluid full-fluid">
                <div className="row">
                    <div className="col-lg-5 h-100 d-flex justify-content-center flex-column corte_diagonal">
                        <div className="col-12 brad_crumbs_nosotros">
                            <BreadCrumbs props={[
                                {name:'Home',route:settings.base_url +"",location:''},
                                {name:'Quienes somos',route:'',location:'',active:true}
                            ]}/>
                        </div>
                        <span className="text_acerca mb-lg-4">Acerca de</span>
                        <h2 className="title_nosotros">Nosotros</h2>
                        {/* <h5>
                            EXPERIENCIA Y SERIEDAD<br />
                        </h5>
                        <h5>
                            En 1983 Inmobiliaria Fanelli Trabalón nació y creció <br class="d-none d-lg-block" /> para brindarle soluciones con agilidad y creatividad comercial.
                        </h5> */}
                        <div className="content-logo position-relative">
                        <   img className="logo-absolute rotating position-absolute" src="assets/images/circle_text_red.svg" alt="Honestidad - Profesionalismo - Confidencialidad - Transparencia" />
                        </div>

                    </div>
                    <div className="col-lg-7 ps-0 h-100 h-sm-auto div_body_mayor">
                        {/* <img src="./assets/images/nosotros-1.jpg" alt="" /> */}
                        <div className="row content-main ms-0 d-block">
                            
                            <div className="div_body_nosotros">
                                <div className="col-lg-12 title">
                                    <h3>Una empresa familiar con más de 40 años en el rubro inmobiliario.</h3>
                                </div>
                                <div className="col-lg-12 text">
                                    <p>
                                    Nuestro vínculo con el Casco Histórico de la Ciudad data desde nuestros bisabuelos, quienes al llegar a la Argentina se instalaron en los barrios de San Telmo y La Boca. A fines de los ´60, fue nuestro padre el Arq. Osvaldo Giesso quien redescubrió la zona. Supo ver que tenía un gran potencial y al constatar que abundaban propiedades antiguas con techos altos, patios y galerías, optó por comprar en el año ´67 una vieja casona ubicada en Cochabamba 360 para mudar allí su estudio de arquitectura.                                    
                                    <br class="d-none d-lg-block" />
                                    <br class="d-none d-lg-block" />
                                    Osvaldo se propuso rejuvenecer el barrio y con la ayuda de sus amigos artistas del Instituto Di Tella, llevó adelante diversas intervenciones en las propiedades. Casas antiguas que eran el fiel testimonio de una época pasada, fueron vistas por los artistas como espacios de experimentación. A su vez, San Telmo consolidó este cambio de estilo gracias al aporte fundamental del Arq. Peña, quien creó en los años `70 la Feria de la Plaza Dorrego y fomentó la llegada de los anticuarios. Además, Peña fue clave en la preservación del patrimonio al crear el Área de Protección Histórica.
                                    <br class="d-none d-lg-block" />
                                    <br class="d-none d-lg-block" />
                                    En 1971 nuestro padre amplió la antigua casona de Cochabamba para dar vida a “Los Teatros de San Telmo” y luego vino la galería de arte “Espacio Giesso”. Naturalmente la casa se convirtió en un centro de referencia barrial que dio origen en 1980 a GIESSO PROPIEDADES.
                                    <br class="d-none d-lg-block" />
                                    <br class="d-none d-lg-block" />
                                    En 1987 empezamos con la revalorización de los “lofts” en los viejos talleres de CANALE en Finochietto 611 y en la ex Fábrica de HILERET ubicada en Brasil 123. También vendimos muchas viejas casonas a arquitectos y artistas plásticos que decidieron venir a vivir al barrio, ponerlas en valor y evitar así una demolición segura.
                                    <br class="d-none d-lg-block" />
                                    <br class="d-none d-lg-block" />
                                    Ya somos tres generaciones que hicimos posible que la inmobiliaria creciera, junto a un equipo de trabajo que nos acompaña, muchos de los cuales han tenido aquí su primera experiencia laboral. Actualmente somos reconocidos en la zona y muchos clientes devinieron en amigos. Algunos incluso están radicados en diferentes partes del mundo y desde allí, nos consultan y confían sus propiedades.
                                    <br class="d-none d-lg-block" />
                                    <br class="d-none d-lg-block" />
                                    Siempre estuvimos y estaremos a favor de la preservación del Casco Histórico porque es parte de nuestra identidad y a tal fin colaboramos con las más reconocidas entidades barriales, apoyando toda iniciativa que tienda a mejorar y mantener su espíritu. Apreciamos las características que hacen de esta zona un lugar único en la Ciudad. Tal es así que actualmente estamos instalados en una antigua “casa chorizo” reciclada con dos patios y jardín, ubicada en Cochabamba 524.
                                    <br class="d-none d-lg-block" />
                                    <br class="d-none d-lg-block" />
                                    Gracias por acompañarnos desde hace tantos años, siempre serán bienvenidos a nuestra casa.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Main