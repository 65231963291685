import React, {useEffect, useState} from 'react'
import { Link } from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux'
import { changeLocationAction } from '../../redux/themeDucks';
import { getPropertiesAction } from '../../redux/propertiesDucks';
import {getFrontCover, getLocationProperty, getPrice, shortDirection, addFavorites, getfavorites} from '../../functions/functions_property'

function Tasaciones (){

    const dispatch = useDispatch();
    const settings = useSelector((store) => store.settings);
    const properties = useSelector((store) => store.properties.properties).data;

    const [featured,setFeatured] = useState([]);

    useEffect(() => {
        dispatch(getPropertiesAction());
    },[])
    useEffect(() => {
        if(properties){
            setFeatured(properties.objects);
        }
    },[properties])

    

    return properties && featured.length > 0 ? (
        <div className="tasaciones">
            <div className="container-fluid">
                <div className="row justify-content-lg-between">
                    <div className="col-lg-6 d-flex flex-column  align-items-lg-start align-items-center">
                        <h2>Conocé <br />Nuestras propiedades <span className="red-color">destacadas.</span></h2>
                        <span className="small-text mb-5">Estás a un click de encontrar tu próxima vivienda.</span>
                        <div className="featured position-relative prop-dest-1" style={{backgroundImage:'url(' + getFrontCover(featured[0].photos).original + ')'}}>
                            {/* <img src={getFrontCover(featured[0].photos)} alt="" /> */}
                            <div className="featured__content p-3">
                                <h4 className="featured__content__title text-center"> {featured[0].publication_title}</h4>
                                <span className="featured__content__location mb-3">
                                    <i class="icon-location"></i>
                                    {featured[0].location.name}
                                </span>
                                <Link onClick={() => dispatch(changeLocationAction("propiedad"))} to={settings.base_url + "propiedad/" + shortDirection(featured[0].operations[0].operation_type , featured[0].type.name, featured[0].location.name , featured[0].id , featured[0].fake_address)} class="btn featured__content__btn d-flex justify-content-center align-items-center go-top animate__animated animate__zoomIn active">
                                    <span class="icon-arrow"></span>
                                </Link>
                            </div>
                        </div>
                        <div className="featured position-relative prop-dest-1" style={{backgroundImage:'url(' + getFrontCover(featured[1].photos).original + ')'}}>
                            <div className="featured__content p-3">
                                <h4 className="featured__content__title">{featured[1].publication_title}</h4>
                                <span className="featured__content__location mb-3">
                                    <i class="icon-location"></i>
                                    {featured[1].location.name}
                                </span>
                                
                                <Link onClick={() => dispatch(changeLocationAction("propiedad"))} to={settings.base_url + "propiedad/" + shortDirection(featured[1].operations[0].operation_type , featured[1].type.name, featured[1].location.name , featured[1].id , featured[1].fake_address)} class="btn featured__content__btn d-flex justify-content-center align-items-center go-top animate__animated animate__zoomIn active">
                                    <span class="icon-arrow"></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 d-flex flex-column  align-items-lg-start align-items-center">
                        <div className="featured position-relative prop-dest-2" style={{backgroundImage:'url(' + getFrontCover(featured[2].photos).original + ')'}}>
                            <div className="featured__content p-3">
                                <h4 className="featured__content__title">{featured[2].publication_title}</h4>
                                <span className="featured__content__location mb-3">
                                    <i class="icon-location"></i>
                                    {featured[2].location.name}
                                </span>
                                <Link onClick={() => dispatch(changeLocationAction("propiedad"))} to={settings.base_url + "propiedad/" + shortDirection(featured[2].operations[0].operation_type , featured[2].type.name, featured[2].location.name , featured[2].id , featured[2].fake_address)} class="btn featured__content__btn d-flex justify-content-center align-items-center go-top animate__animated animate__zoomIn active">
                                    <span class="icon-arrow"></span>
                                </Link>
                            </div>
                        </div>
                        <div className="featured position-relative prop-dest-2" style={{backgroundImage:'url(' + getFrontCover(featured[3].photos).original + ')'}}>
                            <div className="featured__content p-3">
                                <h4 className="featured__content__title">{featured[3].publication_title}</h4>
                                <span className="featured__content__location mb-3">
                                    <i class="icon-location"></i>
                                    {featured[3].location.name}
                                </span>
                                <Link onClick={() => dispatch(changeLocationAction("propiedad"))} to={settings.base_url + "propiedad/" + shortDirection(featured[3].operations[0].operation_type , featured[3].type.name, featured[3].location.name , featured[3].id , featured[3].fake_address)} class="btn featured__content__btn d-flex justify-content-center align-items-center go-top animate__animated animate__zoomIn active">
                                    <span class="icon-arrow"></span>
                                </Link>
                            </div>
                        </div>
                        <div className="featured position-relative prop-dest-2" style={{backgroundImage:'url(' + getFrontCover(featured[4].photos).original + ')'}}>
                            <div className="featured__content p-3">
                                <h4 className="featured__content__title">{featured[4].publication_title}</h4>
                                <span className="featured__content__location mb-3">
                                    <i class="icon-location"></i>
                                    {featured[4].location.name}
                                </span>
                                <Link onClick={() => dispatch(changeLocationAction("propiedad"))} to={settings.base_url + "propiedad/" + shortDirection(featured[4].operations[0].operation_type , featured[4].type.name, featured[4].location.name , featured[4].id , featured[4].fake_address)} class="btn featured__content__btn d-flex justify-content-center align-items-center go-top animate__animated animate__zoomIn active">
                                    <span class="icon-arrow"></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center mt-5 div_button_mas_tasaciones">
                    <Link to={settings.base_url + "ventas"}  onClick={() => dispatch(changeLocationAction("ventas"))} 
                        className="btn transparent d-inline-block mt-auto ver_footer_tasaciones"><span></span><span></span><div className="background_buttom_ver_mas"></div><p>VER MÁS</p> </Link>
                </div>
            </div>
        </div>
    ) :''
}

export default Tasaciones
