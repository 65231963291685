// constantes
const dataInicial = {
    name:'Giesso Propiedades',
    short_name:'giesso-propiedades',
    base_url:'/',
    // base_url:'/',
    seo:{
        title:'Giesso Propiedades'
    }
    ,
    social:
    {
        facebook:'https://www.facebook.com/giessoprop',
        instagram:'https://www.instagram.com/giessoprop/',
        twitter:'',
        linkedin:'',
        youtube:'',
    },
    contact:
    {
        phone:['+5411 4631-1887' , '+5411 4631-7170'],
        whatsapp:{link:'5491134796039 ',visual:'+549 11 3479-6039'},
        mail:'info@giessoprop.com.ar'
    },
    location:
    {
        address:'Cochabamba 524',
        region:'San Telmo, Buenos Aires',
        cp:'C1150AAH'
    },
    keys:{
        tokko:'09ce17e5776743c8f0a68f7e19bcb52f7bb8c53d',
        // tokko:'7d5dc24fcb137714860c3c5924cf3ac62acb3b9a',
        google:'',
        captcha:'6LdbrrAbAAAAAGfnMeQRVgknA9wis0AYma3ZmbMR',
        google_tgm:'',
        google_map:'AIzaSyDOdrBLaZgydSYoSpIRywmFxjTECvbiFcA',
    }
}
export default function settingsReducer(state = dataInicial, action){
    return state
}
