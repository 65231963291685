import React, {useState, useEffect, useRef} from 'react'
import Search from '../autoComplete'
import { useHistory } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";

import { clearPropertiesAction } from "../../redux/propertiesDucks";
import { changeLocationAction } from "../../redux/themeDucks";

function Main (){
    const history = useHistory()
    const dispatch = useDispatch();
    const refOperation = useRef()
    const refType = useRef()

    const settings = useSelector(store => store.settings)
    const {data} = useSelector(store => store.properties.listFilters)

    const [locations,setLocation] = useState([]) 

    const [router,setRouter] = useState({location:'',type:'departamento',operation:'ventas'})

    useEffect(() => {
        if(data){
            setLocation([])
            for (let index = 0; index < data.objects.locations.length; index++) {
                setLocation(locations => [...locations , data.objects.locations[index].location_name])
            }
        }
    },[data]);

    const updateRoute = (value) =>{
        setRouter((router) => ({
            ...router,
            [value.current.name]:value.current.value
        }))
    }

    const goRoute = event => {
        event.preventDefault()
        dispatch(changeLocationAction(router.operation.toLowerCase()))
        dispatch(clearPropertiesAction())
        history.push(settings.base_url+ router.operation.toLowerCase() + "/" + router.type.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") + "/" + router.location.toLowerCase().replace(/ /g,"-").normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
    }
    return (
        <div className="main">
            <div className="transparencia d-block g-lg-none"></div>
            <div className="container-fluid position-relative">
                <div className="row" id="content-main">
                    <div className="col-lg-5 d-flex align-items-start justify-content-center flex-column ">
                        <h1 className="zindex-3 ">Desde 1980 construyendo una&nbsp;trayectoria en&nbsp;el&nbsp;rubro inmobiliario.</h1>
                        <div className="col-12 div_form_home">
                            <form onSubmit={goRoute} className="row no-gutters">
                                    <div className="col-6 pe-lg-2 zindex-3 ">
                                        <div className="content-fields zindex-3">
                                            <i className="icon-home"></i>
                                            <select ref={refType} onChange={() => updateRoute(refType)} name="type" id="type">

                                                {data ? data.objects.property_types.map((item,index) =>(
                                                    <option value={item.type}>{item.type}</option>
                                                )) : <option>Cargando..</option>}
                                            </select>
                                            <span className="icon-arrow position-absolute text-white"></span>
                                        </div>
                                    </div>
                                    <div className="col-6 ps-lg-2 zindex-3">
                                        <div className="content-fields zindex-3 second">
                                            <select ref={refOperation} onChange={() => updateRoute(refOperation)} name="operation" id="operation">
                                                {data ? 
                                                    data.objects.operation_types.map((item,index) =>(
                                                    item.operation_type == 1 ?
                                                    <option value={item.type}>Ventas</option>
                                                    : item.operation_type == 2 ?
                                                    <option value={item.type}>Alquileres</option>
                                                    : ''
                                                ))  : <option>Cargando..</option> }
                                            </select>
                                            <span className="icon-arrow position-absolute text-white"></span>
                                        </div>
                                    </div>
                                    <div className="col-12 zindex-3">
                                        <div className="content-fields zindex-3 mt-lg-3 mt-2">
                                            <i className="icon-location"></i>
                                            <Search lang={locations} updateRoute={updateRoute}/>
                                        </div>
                                    </div>
                                    <div className="col-12 text-center zindex-3 ms-2 ms-lg-0">
                                        <button className="btn position-relative" type="submit"><span></span><p>BUSCAR</p></button>
                                    </div>
                            </form>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
    ) 
}

export default Main
